<template>
  <div class="information-container">
    <div class="left">
      <div
        v-for="(item, index) in navList"
        :key="index"
        :class="{ current: currentIndex === index }"
        @click="currentIndex = index"
      >
        <div class="icon">
          <img :src="item.icon" />
          <img :src="item.icon1" />
        </div>
        {{ item.name }}
      </div>
    </div>
    <div class="right">
      <div class="t">{{ navList[currentIndex].name }}</div>
      <com-score-list :showTitle="false" :list="scoreList" :show-source="true">
        <template slot-scope="scope">
          <span v-if="currentIndex == '0'"
            >该用户评价了你的作品《{{
              scope.data.musicName || scope.data.listName
            }}》</span
          >
          <span v-if="currentIndex == '1'"
            >你评价了《{{ scope.data.musicName || scope.data.listName }}》</span
          >
        </template>
      </com-score-list>
      <com-page
        :total="total"
        layout="prev, pager, next"
        :page-size="pageSize"
        :currentPage="currentPage"
        @change="pageChange"
      ></com-page>
    </div>
  </div>
</template>
<script>
import comScoreList from "@/components/com/com-score-list";
import comPage from "@/components/com/com-page";
export default {
  components: {
    comScoreList,
    comPage,
  },
  data() {
    return {
      navList: [
        {
          name: "招呼",
          icon: require("../assets/xx1.png"),
          icon1: require("../assets/xx11.png"),
        },
        {
          name: "评价",
          icon: require("../assets/xx2.png"),
          icon1: require("../assets/xx22.png"),
        },
      ],
      currentIndex: 0,
      scoreList: [],
      total: 0,
      pageSize: 5,
      currentPage: 1,
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.userInfo.id;
    },
  },
  watch: {
    currentIndex: {
      immediate: true,
      handler() {
        this.currentPage = 1;
        this.sendGetCommentList();
      },
    },
    "$store.state.user.userInfo": {
      handler(v) {
        !v && this.$router.push("/");
      },
    },
  },
  methods: {
    sendGetCommentList() {
      let params = {
        size: this.pageSize,
        current: this.currentPage,
      };

      if (this.currentIndex === 0) {
        params.ownerId = this.userId;
      } else {
        params.userId = this.userId;
      }
      this.$api.commentList(params).then((resolve) => {
        this.total = resolve.data.total;
        this.scoreList = resolve.data.records.map((item) => {
          return {
            username: item.userName || "用户" + item.userId,
            fraction: item.score,
            time: item.gmtCreate,
            musicName: item.musicName,
            listName: item.listName,
            userIcon: item.userIcon,
          };
        });
      });
    },
    pageChange(currentPage) {
      this.currentPage = currentPage;
      this.sendGetCommentList();
    },
  },
  mounted() {
    this.$api.resetUnLookNum().then((resolve) => {
      if (resolve.code === 200) {
        this.$store.commit("SET_LOOK_NUM", 0);
      }
    });
  },
};
</script>
<style scoped lang="scss">
.information-container {
  display: flex;
  margin-top: 68px;
  align-items: flex-start;
}
.left {
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
  > div {
    height: 55px;
    line-height: 55px;
    color: #333333;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 20px;
      height: 20px;
      margin: 0 30px 0 20px;
      display: flex;
      img {
        width: 100%;
      }
      > img:nth-child(1) {
        display: none;
      }
      > img:nth-child(2) {
        display: block;
      }
    }
  }
  > div.current,
  > div:hover {
    color: #fff;
    background: #c20c0c;

    .icon {
      > img:nth-child(1) {
        display: block;
      }

      > img:nth-child(2) {
        display: none;
      }
    }
  }
}
.right {
  flex-grow: 1;
  width: 0;
  margin-left: 100px;
  .t {
    border-bottom: #c20c0c solid 1px;
    color: #333333;
    font-size: 28px;
    padding-bottom: 10px;
    font-weight: 600;
    margin-bottom: 41px;
  }
}
</style>
